import { ChequeStateEnum } from "../../models/Cheque"

export const getChequeStateColor = (state: ChequeStateEnum) => {
  switch (state) {
    case ChequeStateEnum.Portfolio:
      return "warning"
    case ChequeStateEnum.Collected:
      return "success"
    case ChequeStateEnum.Returned:
      return "info"
    case ChequeStateEnum.Circulated:
      return "primary"
    case ChequeStateEnum.NotCollected:
      return "danger"
    default:
      return "info"
  }
}
