import { IPaginatable } from "./IPaginatable"
import { SelectModelInt } from "./SelectModel"

export interface Cheque {
  id: string
  type: number
  typeName?: string
  state: number
  stateName?: string
  bankName?: string
  indorserUser?: string
  dueDate: Date
  amount: number
  issuePlace?: string
  chequeNumber?: string
  branchName?: string
  frontImageUrl?: string
  backImageUrl?: string
  transactions?: Transactions[]
}

export interface Transactions {
  transactionDate: Date
  currentState: number
  currentStateName?: string
  previousState?: number
  previousStateName?: string
  indorserAccountCardName?: string
}

export interface ChequeSearchDto extends IPaginatable {
  branchId: string
  // chequeNumber?: string
  types?: number[]
  states?: number[]
  startDate?: Date
  endDate?: Date
}

export interface ChequeListDto {
  id: string
  bankName?: string
  chequeNumber?: string
  dueDate: Date
  amount: number
  state: number
  stateName?: string
  type: number
  typeName?: string
  frontImageUrl?: string
  backImageUrl?: string
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ChequeState extends SelectModelInt {}

// State => Portföyde (0), Tahsil Edildi (1), Müşteriye İade (2), Ciro Edildi (3), Tahsil Edilemedi (4)
export enum ChequeStateEnum {
  Portfolio = 0,
  Collected,
  Returned,
  Circulated,
  NotCollected,
}

export const staticChequeStateList: ChequeState[] = [
  { id: 0, name: "Portföyde" },
  { id: 1, name: "Tahsil Edildi" },
  { id: 2, name: "Müşteriye İade" },
  { id: 3, name: "Ciro Edildi" },
  { id: 4, name: "Tahsil Edilemedi" },
]
