
import { defineComponent, ref, computed, watch } from "vue"

import { ElForm, UploadProps, UploadUserFile, ElMessageBox } from "element-plus"
import { useRoute, useRouter } from "vue-router"
import { Cheque, ChequeStateEnum } from "@/core/models/Cheque"
import { getChequeStateColor } from "@/core/helpers/entities/cheque"
import ApiService from "@/core/services/ApiService"

export default defineComponent({
  name: "cheque-detail",
  components: {},
  emits: ["getlist"],

  inheritAttrs: false,
  setup(prop, { emit }) {
    const route = useRoute()
    const router = useRouter()
    const apiUrl = process.env.VUE_APP_URL ?? ""

    const isDialogOpen = computed(() => route.meta.chequeDetail)
    const chequeDetailDto = ref<Cheque>({} as Cheque)
    type FormInstance = InstanceType<typeof ElForm>
    const chequeRuleFormRef = ref<FormInstance>()
    const fileList = ref<UploadUserFile[]>([])
    const dialogImageUrl = ref("")
    const dialogImageVisible = ref(false)
    const detailInputDisable = ref(true)

    const selectedState = ref()

    const clearCheque = () => {
      chequeRuleFormRef.value?.resetFields()
      selectedState.value = null
    }

    const handleChequeEditClose = () => {
      router.push({ name: "cheque-list" })
      clearCheque()
      emit("getlist")
    }

    watch(
      () => route.meta.chequeDetail,
      async newValue => {
        if (newValue) {
          openDialog()
        }
      }
    )

    const openDialog = async () => {
      await getCheque(route.params.cheque_id)
    }

    const getCheque = async id => {
      const response = await ApiService.get("cheque/" + id)
      chequeDetailDto.value = response.data
      fileList.value = [
        {
          name: "frontImage",
          url: `${apiUrl + "/" + chequeDetailDto.value.frontImageUrl}`,
        },
        {
          name: "backImage",
          url: `${apiUrl + "/" + chequeDetailDto.value.backImageUrl}`,
        },
      ]
    }

    const updateState = async () => {
      if (!selectedState.value) return
      ElMessageBox.confirm("Bu işlem geri alınamaz. Devam etmek istiyor musunuz?", "Dikkat", {
        confirmButtonText: "Evet",
        cancelButtonText: "İptal",
        type: "warning",
      }).then(async () => {
        try {
          await ApiService.post(`cheque/${route.params.cheque_id}/state/${selectedState.value}`, "")
          handleChequeEditClose()
        } catch {
          selectedState.value = null
        }
      })
    }

    const handlePictureCardPreview: UploadProps["onPreview"] = uploadFile => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dialogImageUrl.value = uploadFile.url!
      dialogImageVisible.value = true
    }

    const options = computed(() => {
      const filteredOptions = [] as { value: ChequeStateEnum; label: string; type: string }[]

      if (chequeDetailDto.value.state === ChequeStateEnum.NotCollected) {
        // "ChequeStateEnum.NotCollected" durumunda sadece "Müşteriye İade" seçeneğini göster
        filteredOptions.push({
          value: ChequeStateEnum.Returned,
          label: "Müşteriye İade",
          type: getChequeStateColor(ChequeStateEnum.Returned),
        })
      } else if (
        chequeDetailDto.value.state !== ChequeStateEnum.Collected &&
        chequeDetailDto.value.state !== ChequeStateEnum.Returned
      ) {
        // "ChequeStateEnum.Collected" ve "ChequeStateEnum.Returned" durumunda hiçbir seçenek gösterme
        // "ChequeStateEnum.NotCollected" ve diğer durumlar için "Tahsil Edilemedi" ve "Tahsil Edildi" seçeneklerini göster
        filteredOptions.push(
          {
            value: ChequeStateEnum.Collected,
            label: "Tahsil Edildi",
            type: getChequeStateColor(ChequeStateEnum.Collected),
          },
          {
            value: ChequeStateEnum.NotCollected,
            label: "Tahsil Edilemedi",
            type: getChequeStateColor(ChequeStateEnum.NotCollected),
          },

          {
            value: ChequeStateEnum.Returned,
            label: "Müşteriye İade",
            type: getChequeStateColor(ChequeStateEnum.Returned),
          }
        )
      }

      return filteredOptions
    })

    return {
      isDialogOpen,
      handleChequeEditClose,
      handlePictureCardPreview,
      chequeDetailDto,
      fileList,
      dialogImageVisible,
      dialogImageUrl,
      apiUrl,
      detailInputDisable,
      updateState,
      selectedState,
      ChequeStateEnum,
      getChequeStateColor,
      options,
    }
  },
})
