
import ApiService from "@/core/services/ApiService"
import { defineComponent, onMounted, ref, computed } from "vue"
import { Edit } from "@element-plus/icons-vue"
import { SelectModel } from "@/core/models/SelectModel"
import { ElForm } from "element-plus"
import { getRule, RuleTypes } from "@/core/helpers/rules"
import { getChequeStateColor } from "@/core/helpers/entities/cheque"
import {
  firstDayofMonth,
  lastDayofMonth,
  dateForm,
  DateFormatTypes,
} from "@/core/helpers/dateformat"
import { ChequeSearchDto, staticChequeStateList, ChequeListDto } from "@/core/models/Cheque"
import { currencyForm } from "@/core/helpers/currencyformat"
import { useRouter } from "vue-router"
import chequeDetail from "@/components/finance/cheque/cheque.vue"

export default defineComponent({
  name: "cheque-list",
  components: { chequeDetail },
  setup() {
    const router = useRouter()
    type FormInstance = InstanceType<typeof ElForm>
    const ruleFormRef = ref<FormInstance>()

    const isChequeListSelectLoading = ref(false)
    const isBusinessSelectLoading = ref(false)
    const isBranchSelectLoading = ref(false)
    const isCashBookSelectLoading = ref(false)

    const totalCount = ref<number>(0)

    const chequeSearch = ref<ChequeSearchDto>({
      _pageNumber: 1,
      _pageSize: 10,
      startDate: firstDayofMonth(),
      endDate: lastDayofMonth(),
    } as ChequeSearchDto)

    const chequeList = ref<ChequeListDto[]>([])
    const branchList = ref<SelectModel[]>([])
    const businessList = ref<SelectModel[]>([])
    const activeBusinessId = ref<string>()
    const rules = ref({
      branchId: getRule(RuleTypes.SELECT, "Şube"),
    })

    const formData = computed(() => ({
      activeBusinessId: activeBusinessId.value,
      ...chequeSearch.value,
    }))

    const handChequeDetail = id => {
      router.push({
        name: "cheque-detail",
        params: { cheque_id: id },
      })
    }

    const OnChangeBusiness = async businessId => {
      resetForm()
      branchList.value = []
      await getBranchList(businessId)
    }

    const resetForm = () => {
      chequeSearch.value = {
        ...chequeSearch.value,
        startDate: firstDayofMonth(),
        endDate: lastDayofMonth(),
      }
    }

    const getBusinessList = async () => {
      const { data } = await ApiService.get("business")
      businessList.value = data.filter(x => x.isActive).map(x => ({ id: x.id, name: x.title }))
      if (businessList.value.length === 1) {
        activeBusinessId.value = businessList.value[0].id
        await OnChangeBusiness(activeBusinessId.value)
      }
    }

    const getBranchList = async businessId => {
      isBranchSelectLoading.value = true
      const config = {
        params: {
          businessId: businessId,
        },
      }
      const { data } = await ApiService.query("branch", config)
      isBranchSelectLoading.value = false
      branchList.value = data.filter(x => x.isActive).map(x => ({ id: x.id, name: x.title }))
      if (branchList.value.length === 1) {
        chequeSearch.value.branchId = branchList.value[0].id
      }
    }

    const formValidate = async formEl => {
      if (!formEl) return
      formEl.validate(async valid => {
        if (valid) {
          getChequeList()
        }
      })
    }

    const getChequeList = async () => {
      const config = {
        params: {
          ...chequeSearch.value,
        },
      }
      isChequeListSelectLoading.value = true
      try {
        const { data } = await ApiService.query("cheque/", config)
        chequeList.value = data._data
        totalCount.value = data._totalData
        chequeSearch.value._pageCount = data._pageCount
      } finally {
        isChequeListSelectLoading.value = false
      }
    }

    const handleSizeChange = (val: number) => {
      const data = chequeSearch.value
      data._pageSize = val
      chequeSearch.value = data
      getChequeList()
    }
    const handleCurrentChange = (val: number) => {
      const data = chequeSearch.value
      data._pageNumber = val
      chequeSearch.value = data
      getChequeList()
    }

    onMounted(async () => {
      await getBusinessList()
    })

    return {
      Edit,
      rules,
      formData,
      chequeSearch,
      ruleFormRef,

      isBusinessSelectLoading,
      isBranchSelectLoading,
      isCashBookSelectLoading,
      isChequeListSelectLoading,

      dateForm,
      getBusinessList,
      getBranchList,
      OnChangeBusiness,
      getChequeList,
      formValidate,
      currencyForm,
      handChequeDetail,
      chequeDetail,

      totalCount,
      handleSizeChange,
      handleCurrentChange,

      activeBusinessId,
      branchList,
      businessList,
      chequeList,
      DateFormatTypes,
      staticChequeStateList,
      getChequeStateColor,
    }
  },
})
